import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ExperiencePage from "../components/experience"

const IndexPage = () => {
  return (
    <Layout wide>
      <SEO title="Udara's experience" />
      <div className="work">
        <ExperiencePage />
      </div>
    </Layout>
  )
}

export default IndexPage
