import React, { useState } from "react"
import * as styles from "./experience.module.scss"
import WORK from "./experience.json"
import { GitHubLogo } from "../icons"

const ExperiencePage = () => {
  const [focused, setFocus] = useState(false)

  const toggleFocus = src => {
    if (focused === false || focused !== src) {
      setFocus(src)
      return
    }

    setFocus(false)
  }

  const renderWork = () => {
    return WORK.map(w => {
      return (
        <div className={styles.item} id={w.name}>
          <div className={styles.content}>
            <div className={styles.info}>
              <div className={styles.name}>{w.name}</div>
              <div className={styles.des}>{w.des}</div>
            </div>
          </div>
          <div className={styles.timeline}>
            {w.start_year} - {w.end_year}
          </div>
        </div>
      )
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.highlight}>
          <div className={styles.title}>Experience</div>
          I make products and build things with friends, startups and
          organizations. I believe in making things that bring the power and
          magic of technology to more people. I'm also a bioinformatician by
          training and spend a lot of time thinking about the intersection of
          biology and technology.
          <br />
          <br />I founded Clew, worked at Dropbox and Shopify making products,
          and I've also worked with many startups and organizations building
          products and communities.
        </div>
      </div>
      <div className={styles.portfolio}>{renderWork()}</div>
    </div>
  )
}

export default ExperiencePage
