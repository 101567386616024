// extracted by mini-css-extract-plugin
export var content = "experience-module--content--38daf";
export var des = "experience-module--des--88977";
export var header = "experience-module--header--01098";
export var highlight = "experience-module--highlight--b8652";
export var info = "experience-module--info--da160";
export var item = "experience-module--item--cd8de";
export var name = "experience-module--name--a8f96";
export var portfolio = "experience-module--portfolio--f282c";
export var timeline = "experience-module--timeline--b3a03";
export var title = "experience-module--title--d7203";